<template>
  <div>
    <b-card class="dense">
      <div class="text-center h4 p-0 m-0 text-dark">
        Review and Place Order
      </div>
    </b-card>
    <b-row>
      <b-col sm="6" md="6" lg="6" class=" offset-md-0 offset-lg-0">
        <div v-if="cart.length" class="mb-1" style="">
          <div
            class="font-weight-bolder text-dark font-medium-3 text-dark text-center pb-0 pt-2 d-flex justify-content-between"
          >
            <span>Your Items</span> <span class="font-weight-bolder" />
          </div>
          <div
            v-for="(item, index) in cart"
            :key="item.name + index"
            class="d-flex flex-column py-1"
            style="border-bottom: 1px solid #ccccccdd;"
          >
            <div class="d-flex justify-content-between flex-grow-1">
              <div class="d-flex align-items-center">
                <span
                  class="text-dark font-weight-bolder font-medium-1 text-center d-inline-block"
                  style="background: #dddddd00; width: 32px; min-width:32px; height: 32px;line-height: 32px"
                  >{{ item.quantity }}
                  <feather-icon icon="XIcon" size="13"> </feather-icon>
                </span>
                <div class="ml-1">
                  <div class="text-dark font-weight-bolder font-medium-1">
                    {{ item.name }}
                  </div>
                  <div
                    v-if="item.modifiers && item.modifiers.length > 0"
                    style="padding: 6px 5px 3px 0"
                  >
                    <span
                      v-for="(modifier, index2) in item.modifiers"
                      :key="index2"
                      style="padding-right: 3px;"
                      class="font-weight-bold"
                    >
                      {{ modifier.name }}(${{ (modifier.price || 0).toFixed(2) }})</span
                    >
                  </div>
                </div>
              </div>
              <div class="d-flex justify-content-around">
                <div
                  class="d-flex flex-column align-items-center justify-content-around h-100"
                >
                  <h5 class="ml-1 text-dark font-weight-bolder font-medium-1">
                    ${{ item.totalPrice }}
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex flex-column justify-content-around align-items-center">
          <div class="d-flex flex-column w-100" style="padding: 2px">
            <div class="price-details font-weight-bold">
              <div class="detail-title">
                Subtotal
              </div>
              <div>${{ subTotal }}</div>
            </div>
            <div class="price-details  font-weight-bold">
              <div class="detail-title">
                Tip
              </div>
              <div>${{ tip }}</div>
            </div>
            <div class="price-details mb-0 font-weight-bold">
              <div class="detail-title">
                Tax and fees
                <feather-icon id="popover-tax" icon="InfoIcon" size="15" />
              </div>
              <b-popover target="popover-tax" triggers="hover" placement="top">
                <template #title>
                  Taxes and Fees
                </template>
                <div class="font-weight-bold">
                  Sales Tax - ${{ tax }} <br />
                  Service Fees: ${{ serviceFees }}
                </div>
              </b-popover>
              <div>${{ (tax * 100 + serviceFees * 100) / 100 }}</div>
            </div>
            <hr class="w-100" />
            <div
              class="mb-1 text-dark font-weight-bolder font-medium-2 d-flex justify-content-between"
            >
              <div class="detail-title">
                Total
              </div>
              <div>${{ total }}</div>
            </div>
          </div>
        </div>
      </b-col>
      <b-col sm="6" md="6" lg="6" class=" offset-md-0 offset-lg-0">
        <div>
          <div
            class="font-weight-bolder text-dark font-medium-3 text-dark text-center pt-3 d-flex justify-content-between"
          >
            <span>Delivery</span> <span class="font-weight-bolder" />
          </div>
          <div class="font-weight-bold font-medium-1 my-1 d-flex justify-content-between">
            <span class="text-dark">Address</span>
            <span>{{ hotel.name.substring(0, 20) }} #{{ hotel.roomNumber }}</span>
          </div>
          <div
            class="font-weight-bold font-medium-1 my-1 d-flex justify-content-between align-items-center"
          >
            <span class="text-dark">Instructions</span>
            <span class="d-flex ">
              <span
                v-if="deliveryInstructions"
                class="text-nowrap overflow-hidden ml-1"
                style="max-width: 45vw; text-overflow: ellipsis"
                @click="showDeliveryInstructionModal = true"
                >{{ deliveryInstructions }}</span
              >
              <span v-else @click="showDeliveryInstructionModal = true">Add note</span>
              <feather-icon
                icon="EditIcon"
                size="18"
                class="ml-1"
                @click="showDeliveryInstructionModal = true"
              />
            </span>
          </div>
          <b-modal
            v-model="showDeliveryInstructionModal"
            centered
            ok-only
            ok-title="Save"
            title="Instructions"
          >
            <b-form-textarea
              id="textarea-state"
              v-model="deliveryInstructions"
              class="font-weight-bold font-medium-1 text-dark"
              style="border-width: 2px;"
              placeholder="Add any special request (e.g., Leave the food at doorstep, and ring the bell once!, etc.) and the delivery person will do its best to accommodate you."
              rows="5"
            />
          </b-modal>
        </div>

        <div>
          <div
            class="font-weight-bolder text-dark font-medium-2 text-dark text-center pb-1 pt-3 d-flex justify-content-between"
          >
            <span>Tip</span> <span class="font-weight-bolder">${{ tip }}</span>
          </div>
          <div class="d-flex overflow-auto pb-1 ddash-form-radio-group-div">
            <!-- radio button -->
            <b-form-group style="overflow-x: auto; ">
              <b-form-radio-group
                v-model="selectedTipOption"
                button-variant="ddash"
                :options="tipOptions"
                buttons
                class="ddash-form-radio-group"
                @change="selectTip"
              />
            </b-form-group>
            <!--            <b-form-group style="margin-left: 2%;">-->
            <!--              <b-form-checkbox-group-->
            <!--                button-variant="gradient-dark"-->
            <!--                :options="[{ text: 'Custom' }]"-->
            <!--                buttons-->
            <!--                @change="showCustomTipModal = true"-->
            <!--              />-->
            <!--            </b-form-group>-->
          </div>

          <b-modal v-model="showCustomTipModal" centered title="Please enter custom tip value">
            <b-form-input
              id="x"
              :value="tip"
              type="number"
              class="mb-5"
              @change="tip = parseFloat($event || '0').toFixed(2)"
            />
            <div slot="modal-footer">
              <div
                class="item d-flex justify-content-end align-items-center px-2  position-absolute bg-white"
                style="box-shadow: none;padding: 15px; bottom: 0; left: 0; right: 0;"
              >
                <b-button
                  class="round-false text-nowrap font-medium-2"
                  variant="dark"
                  @click="showCustomTipModal = false"
                >
                  Add
                </b-button>
              </div>
            </div>
          </b-modal>
        </div>

        <div>
          <div class=" mb-3">
            <div
              class="font-weight-bold font-medium-1 d-flex justify-content-between align-items-center"
            >
              <span class="text-dark font-medium-2 font-weight-bolder">Payment Method</span>
              <!--            <span class="d-flex ">-->
              <!--              <span-->
              <!--                v-if="paymentMethod"-->
              <!--                class="text-nowrap overflow-hidden ml-1"-->
              <!--                style="max-width: 45vw; text-overflow: ellipsis"-->
              <!--                @click="checkButtonsAvailability"-->
              <!--                >{{ paymentMethod.text }}</span-->
              <!--              >-->
              <!--              <span v-else @click="checkButtonsAvailability">{{ paymentMethod.text }}</span>-->
              <!--              <feather-icon-->
              <!--                icon="ChevronRightIcon"-->
              <!--                size="18"-->
              <!--                class="ml-1"-->
              <!--                @click="checkButtonsAvailability"-->
              <!--              />-->
              <!--            </span>-->
            </div>

            <div class="p-0">
              <div class="mt-0">
                <div v-if="!loadingInModal">
                  <div
                    v-for="(method, index) in paymentMethodOptions"
                    :key="index"
                    class="d-flex align-items-center justify-content-between"
                    style="margin: 18px 0px"
                    @click="
                      paymentMethod = method
                      showPaymentMethodModal = false
                    "
                  >
                    <div class="d-flex align-items-center">
                      <div style="width: 55px;">
                        <feather-icon
                          v-if="method.icon"
                          :icon="method.icon"
                          size="19"
                          class="mr-1"
                        />
                        <b-img-lazy
                          v-if="method.image"
                          :src="method.image"
                          height="17"
                          class="mr-1"
                        />
                      </div>
                      <div class="font-medium-2 font-weight-bold text-dark">
                        {{ method.text }}
                      </div>
                    </div>
                    <feather-icon
                      v-if="
                        method.value === paymentMethod.value && method.id === paymentMethod.id
                      "
                      icon="CheckIcon"
                      size="16"
                      class="mr-1"
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="d-flex justify-content-center flex-column align-items-center my-4 m-1"
                >
                  <b-spinner style="width: 3rem; height: 3rem;" variant="primary" />
                </div>
                <b-button
                  size="sm"
                  variant="flat-dark"
                  class="d-none font-medium-2 font-weight-bold text-dark mt-2 align-items-center"
                >
                  <feather-icon icon="PlusIcon" size="17" />
                  <span class="ml-1">Add payment method</span>
                </b-button>
              </div>
            </div>
          </div>

          <b-modal
            id="custom-modal"
            v-model="showPaymentMethodModal"
            scrollable
            centered
            ok-title="Save"
            class="custom-modal"
            title="Select Payment Method"
          >
            <div slot="modal-header" class="d-flex p-0 w-100 item">
              <div
                class="item d-flex px-1 w-100"
                style="box-shadow: rgba(0, 0, 0, 0.2) 0 calc(1px) 15px; padding-top: 1.3rem; padding-bottom: 1.3rem"
              >
                <feather-icon
                  icon="XIcon"
                  size="25"
                  class="text-dark"
                  @click="showPaymentMethodModal = false"
                />
                <div
                  class="d-inline ml-1 font-circular text-dark"
                  style="font-size: 1.5rem; white-space: nowrap; text-overflow: ellipsis; width: 85%;
              overflow: hidden;-webkit-line-clamp: 1;"
                >
                  Select Payment Method
                </div>
              </div>
            </div>
            <div class="p-1">
              <div class="mt-1">
                <div class="font-weight-bold text-dark font-medium-3">
                  Payment Methods
                </div>
                <div v-if="!loadingInModal">
                  <div
                    v-for="(method, index) in paymentMethodOptions"
                    :key="index"
                    class="d-flex align-items-center justify-content-between"
                    style="margin: 18px 13px"
                    @click="
                      paymentMethod = method
                      showPaymentMethodModal = false
                    "
                  >
                    <div class="d-flex align-items-center">
                      <feather-icon
                        v-if="method.icon"
                        :icon="method.icon"
                        size="19"
                        class="mr-1"
                      />
                      <b-img-lazy
                        v-if="method.image"
                        :src="method.image"
                        height="17"
                        class="mr-1"
                      />
                      <div class="font-medium-2 text-dark">
                        {{ method.text }}
                      </div>
                    </div>
                    <feather-icon
                      v-if="
                        method.value === paymentMethod.value && method.id === paymentMethod.id
                      "
                      icon="CheckIcon"
                      size="16"
                      class="mr-1"
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="d-flex justify-content-center flex-column align-items-center my-4 m-1"
                >
                  <b-spinner style="width: 3rem; height: 3rem;" variant="primary" />
                </div>
                <b-button
                  size="sm"
                  variant="flat-dark"
                  class="d-none font-medium-2 font-weight-bold text-dark mt-2 align-items-center"
                >
                  <feather-icon icon="PlusIcon" size="17" />
                  <span class="ml-1">Add payment method</span>
                </b-button>
              </div>
            </div>
            <div slot="modal-footer">
              <div
                class="item d-flex justify-content-around align-items-center px-1 py-1 position-absolute bg-white"
                style="box-shadow: rgba(0, 0, 0, 0.2) 0 calc(-1px) 15px; bottom: 0; left: 0; right: 0;"
              >
                <b-button
                  class="round-false text-nowrap font-medium-2"
                  variant="dark"
                  block
                  size="lg"
                  style="border-radius: 0"
                  @click="showPaymentMethodModal = false"
                >
                  Save
                </b-button>
              </div>
            </div>
          </b-modal>
        </div>

        <!--        Card Payment Component-->
        <div>
          <b-modal
            id="custom-modal"
            v-model="showPaymentByCardModal"
            scrollable
            centered
            ok-title="Save"
            class="custom-modal"
          >
            <div slot="modal-header" class="d-flex p-0 w-100 item">
              <div
                class="item d-flex px-1 w-100"
                style="box-shadow: rgba(0, 0, 0, 0.2) 0 calc(1px) 15px; padding-top: 1.3rem; padding-bottom: 1.3rem"
              >
                <feather-icon
                  icon="XIcon"
                  size="25"
                  class="text-dark"
                  @click="showPaymentByCardModal = false"
                />
                <div
                  class="d-inline ml-1 font-circular text-dark"
                  style="font-size: 1.5rem; white-space: nowrap; text-overflow: ellipsis; width: 85%;
              overflow: hidden;-webkit-line-clamp: 1;"
                >
                  Pay with card
                </div>
              </div>
            </div>
            <div class="p-1">
              <div class="mt-1">
                <div v-if="!loadingInModal">
                  <div id="card-element" />
                  <div id="card-error" class="my-2 text-danger font-weight-bold" />
                  <div class="my-3">
                    <b-button
                      id="payWithCardButton"
                      block
                      class="d-flex justify-content-center align-items-center"
                      variant="dark"
                      @click="payWithCard"
                    >
                      <b-spinner
                        v-if="isLoading"
                        style="margin:1px 3px"
                        :disabled="isLoading"/>
                      <span
                        >{{ paymentButtonText[paymentMethod.value] }}
                        <span>&nbsp;•&nbsp;</span>${{ total }}</span
                      >
                      <feather-icon icon="ArrowRightIcon" size="17" class="ml-1"
                    /></b-button>
                  </div>
                </div>
                <div
                  v-else
                  class="d-flex justify-content-center flex-column align-items-center my-4 m-1"
                >
                  <b-spinner style="width: 3rem; height: 3rem;" variant="dark" />
                </div>
              </div>
            </div>
            <div slot="modal-footer">
              <div
                class="item d-flex justify-content-around align-items-center px-1 py-1 position-absolute bg-white"
                style="box-shadow: rgba(0, 0, 0, 0.2) 0 calc(-1px) 15px; bottom: 0; left: 0; right: 0;"
              >
                <b-button
                  class="round-false text-nowrap font-medium-2"
                  variant="dark"
                  block
                  size="lg"
                  style="border-radius: 0"
                  @click="showPaymentByCardModal = false"
                >
                  Close
                </b-button>
              </div>
            </div>
          </b-modal>
        </div>

        <b-button
          block
          size="lg"
          variant="dark"
          class="mb-1 font-weight-bolder"
          style="border-radius: 0"
          :disabled="isLoading || cartCount === 0"
          @click="checkout"
        >
          <b-spinner v-if="isLoading" style="margin:1px 3px" small />
          {{ paymentButtonText[paymentMethod.value] }} <span>&nbsp;•&nbsp;</span>${{ total }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>
<style>
.ddash-form-radio-group-div fieldset {
  width: 100%;
}
.ddash-form-radio-group .btn {
  font-weight: bolder !important;
}
.ddash-form-radio-group {
  border-radius: 25px !important;
  display: flex !important;
  background: #eee;
}
.btn-ddash {
  color: black;
  border: none !important;
}
.btn-ddash {
  background: #eee;
  border-radius: 25px !important;
  border: none;
}
.btn-ddash.active {
  background: #000 !important;
  color: #fff;
}
.price-details {
  display: flex;
  justify-content: space-between;
  font-size: 1.1rem;
  margin-bottom: 8px;
}
</style>
<script>
import {
  BCard,
  BRow,
  BCol,
  BImgLazy,
  BSpinner,
  BButton,
  BFormTextarea,
  BPopover,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  // BFormCheckboxGroup,
} from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Checkout',
  components: {
    BCard,
    BSpinner,
    BImgLazy,
    BRow,
    BCol,
    BButton,
    BFormTextarea,
    BPopover,
    BFormGroup,
    BFormRadioGroup,
    // BFormCheckboxGroup,
    BFormInput,
  },
  data() {
    return {
      customerName: '',
      order: null,
      card: null,
      isLoading: false,
      tip: 0,
      tipOptions: [
        { text: '10%', value: '10' },
        { text: '20%', value: '20' },
        { text: '25%', value: '25' },
        { text: 'Custom', value: '0' },
      ],
      selectedTipOption: 10,
      showCustomTipModal: false,
      showDeliveryInstructionModal: false,
      deliveryInstructions: null,
      showPaymentMethodModal: false,
      showPaymentByCardModal: false,
      loadingInModal: false,
      paymentMethod: {
        value: 'cash',
        text: 'Cash',
        cardNumber: null,
      },
      paymentMethodOptions: [
        {
          text: 'Cash',
          value: 'cash',
          // eslint-disable-next-line global-require
          image: require('../assets/images/payment-methods/cash.svg'),
        },
        { text: 'Room Charge', value: 'room-charge', icon: 'KeyIcon' },
        {
          text: 'Card',
          value: 'card',
          id: 'new',
          icon: 'CreditCardIcon',
        },
      ],
      paymentButtonText: {
        card: 'Pay with Card',
        cash: 'Pay with Cash',
        'room-charge': 'Pay with Room Charges',
        'apple-pay': 'Buy with apple pay',
        'google-pay': 'Buy with Google Pay',
      },
      paymentRequest: null,
      stripeStyle: {
        base: {
          color: '#000000',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#888888',
          },
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: '#b31f00',
          iconColor: '#c62000',
        },
      },
    }
  },
  computed: {
    ...mapState({
      hotel: state => state.hotel.hotel || {},
      cartRestaurant: state => state.restaurant.cartRestaurant,
      user: state => state.user.user,
      showLogin: state => state.user.showLogin,
      cart: state => state.cart.added,
      cartCount: state =>
        state.cart.added.reduce(
          (item, current) => ({ quantity: item.quantity + current.quantity }),
          { quantity: 0 }
        ).quantity,
    }),
    subTotal() {
      let total = 0
      this.cart.forEach(i => {
        total += i.totalPrice
      })
      return total.toFixedNumber(2)
    },
    serviceFees() {
      return this.cartCount > 0 ? 1 : 0
    },
    tax() {
      return (this.subTotal * 0.086).toFixedNumber(2)
    },
    total() {
      return (this.subTotal * 1.086 + this.serviceFees + parseFloat(this.tip)).toFixedNumber(2)
    },
  },
  watch: {
    total(value) {
      if (this.paymentRequest && !this.isLoading) {
        this.paymentRequest.update({
          currency: 'usd',
          total: {
            label: 'Total',
            amount: (value * 100).toFixedNumber(0),
          },
        })
      }
    },
  },
  async created() {
    if (this.cartCount === 0) {
      this.$router.go(-2)
    }
    this.selectTip()
    this.paymentRequest = this.$stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Total',
        amount: ((this.total || 0) * 100).toFixedNumber(0),
      },
      requestPayerName: true,
    })
  },
  async mounted() {
    const canMakePayment = await this.paymentRequest.canMakePayment()
    if (canMakePayment) {
      const googlePay = {
        text: 'Google Pay',
        value: 'google-pay',
        image:
          // eslint-disable-next-line global-require
          require('../assets/images/payment-methods/google-pay.svg'),
      }
      const applePay = {
        text: 'Apple Pay',
        value: 'apple-pay',
        image:
          // eslint-disable-next-line global-require
          require('../assets/images/payment-methods/apple-pay.svg'),
      }

      if (canMakePayment.googlePay) {
        this.paymentMethodOptions.unshift(googlePay)
      }
      if (canMakePayment.applePay) {
        this.paymentMethodOptions.unshift(applePay)
        // Select apple pay by default
        this.paymentMethod = applePay
      } else if (canMakePayment.googlePay) this.paymentMethod = googlePay
    }
    // Listener for buttons
    this.paymentRequest.on('cancel', async () => {
      this.isLoading = false
    })
    this.paymentRequest.on('paymentmethod', async ev => {
      this.isLoading = true

      // eslint-disable-next-line no-unused-vars
      const { clientSecret, orderID } = (
        await this.$http.post('/stripe/create-payment-intent', this.order)
      ).data

      // Confirm the PaymentIntent without handling potential next actions (yet).
      const confirmResult = await this.$stripe.confirmCardPayment(
        clientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false }
      )
      if (!confirmResult || confirmResult.error) {
        ev.complete('fail')
        this.showError(confirmResult.error.message)
      } else {
        ev.complete('success')
        if (confirmResult.paymentIntent.status === 'requires_action') {
          this.$stripe.confirmCardPayment(clientSecret).then(result => {
            if (result.error) {
              this.showError(result.error.message)
              // The payment failed -- ask your customer for a new payment method.
            } else {
              // The payment has succeeded.
              this.orderComplete()
            }
          })
        } else {
          // The payment has succeeded.
          this.orderComplete()
        }
      }
    })
  },
  methods: {
    ...mapActions({
      clearCart: 'cart/clearCart',
      toggleLogin: 'user/toggleLogin',
    }),
    selectTip() {
      if (+this.selectedTipOption === 0) {
        this.showCustomTipModal = true
        return
      }
      this.tip = ((this.subTotal * this.selectedTipOption) / 100).toFixedNumber(2)
    },
    async checkout() {
      try {
        if (!this.user.loggedIn || new Date(this.user.expires) < new Date()) {
          // console.log(this.user)
          this.toggleLogin()
        } else {
          this.isLoading = true
          const { hotel } = this
          this.order = {
            user: this.user,
            hotel: {
              _id: hotel._id,
              name: hotel.name,
              address: hotel.address,
              number: hotel.number,
              email: hotel.email,
              status: hotel.status,
              roomNumber: hotel.roomNumber,
            },
            placed: new Date().toISOString(),
            total: this.total,
            tip: this.tip,
            subTotal: this.subTotal,
            tax: this.tax,
            serviceFees: this.serviceFees,
            deliveryInstructions: this.deliveryInstructions,
            items: this.cart,
            payment: {
              method: this.paymentMethod.value,
            },
            // eslint-disable-next-line no-underscore-dangle
            restaurantID: this.cartRestaurant._id,
            restaurant: {
              // eslint-disable-next-line no-underscore-dangle
              id: this.cartRestaurant._id,
              kattooz_id: this.cartRestaurant.kattooz_id,
              name: this.cartRestaurant.name,
            },
          }
          switch (this.paymentMethod.value) {
            case 'room-charge':
            case 'cash': {
              try {
                // eslint-disable-next-line no-unused-vars
                const { insertedResult } = (await this.$http.post('/orders', this.order)).data
                await this.clearCart()
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-center',
                  props: {
                    title: 'Order Placed Successfully',
                    icon: 'CheckIcon',
                    variant: 'success',
                  },
                })

                this.$router.push('orders')
              } catch (e) {
                // console.error(e)
              }
              break
            }
            case 'apple-pay':
            case 'google-pay': {
              this.paymentRequest.show()
              break
            }
            default: {
              this.showPaymentByCardModal = true
              this.isLoading = false
              setTimeout(this.initCard, 200)
            }
          }
          // await this.$router.push('checkout')
        }
      } catch (e) {
        this.isLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-center',
          props: {
            title: e.message,
            icon: 'AlertCircleIcon',
            variant: 'danger',
          },
        })
      }
    },
    async checkButtonsAvailability() {
      this.showPaymentMethodModal = true
      this.loadingInModal = true
      this.loadingInModal = false
    },
    submitForm() {
      this.$refs['payment-form'].$el.submit()
    },
    initCard() {
      const elements = this.$stripe.elements()
      this.card = elements.create('card', { style: this.stripeStyle })
      // Stripe injects an iframe into the DOM
      this.card.mount('#card-element')
      this.card.on('change', event => {
        // Disable the Pay button if there are no card details in the Element
        document.querySelector('#payWithCardButton').disabled = event.empty
        document.querySelector('#card-error').textContent = event.error
          ? event.error.message
          : ''
      })
    },
    async payWithCard() {
      try {
        this.isLoading = true

        // eslint-disable-next-line no-unused-vars
        const { clientSecret, orderID } = (
          await this.$http.post('/stripe/create-payment-intent', this.order)
        ).data
        this.$stripe
          .confirmCardPayment(clientSecret, {
            payment_method: {
              card: this.card,
            },
          })
          .then(result => {
            if (result.error) {
              // Show error to your customer
              this.showError(result.error.message)
            } else {
              // The payment succeeded!
              this.orderComplete(result.paymentIntent.id)
            }
          })
      } catch (e) {
        this.isLoading = false
        this.showError(e.message)
      }
    },
    updateItemQuantity(cartItemId, quantity) {
      if (quantity === 0) {
        this.$store.dispatch('cart/removeItemFromCart', cartItemId)
      } else this.$store.dispatch('cart/updateCartItemQuantity', { cartItemId, quantity })
    },
    orderComplete() {
      this.$toast({
        component: ToastificationContent,
        position: 'top-center',
        props: {
          title: 'Order Placed Successfully',
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
      setTimeout(() => {
        this.clearCart()
        this.isLoading = false
        this.$router.push('orders')
        // this.$router.push({ path: this.hotel.path || '/' })
      }, 900)
    },
    showError(errorMsgText) {
      this.isLoading = false
      this.$toast({
        component: ToastificationContent,
        position: 'top-center',
        props: {
          title: errorMsgText,
          icon: 'AlertCircleIcon',
          variant: 'danger',
        },
      })
    },
  },
}
</script>

<style scoped></style>
